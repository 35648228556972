import { chakra, Flex } from "@chakra-ui/react";
import { HOVER_STYLE } from "src/theme/hover-style";

export const HoverCard = chakra(Flex, {
    baseStyle: {
        display: "flex",
        p: 2,
        bg: "white",
        boxShadow: "sm",
        borderRadius: "8px",
        ...HOVER_STYLE,
    },
});
