import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { IconArrowRight } from "src/components/icons";

export const ArrowButton = (props: IconButtonProps) => (
    <IconButton
        icon={<IconArrowRight boxSize="24px" color="gray.800" />}
        size="sm"
        bg="white"
        variant="outline"
        borderColor="gray.200"
        borderRadius="50%"
        {...props}
    />
);
