// eslint-disable-next-line no-restricted-imports
import { useRef } from "react";
import { Text, Flex, Box } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { useTranslation } from "next-i18next";
import { useDownloadMutation } from "src/api/client/detail-page-download.api";
import { Section, SectionProps, Span } from "src/components/base";
import { Button } from "src/components/base/button";
import { HashLinkUnstyled } from "src/components/base/hash-link";
import { WfHeading } from "src/components/base/wf-heading";
import { IconHidden } from "src/components/icons";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";
import { EnumDownloadType } from "src/types/common-enum";
import { HIDE_CONTENT_TEST_ID } from "./hide-content.test-ids";

interface IHideContentProps extends SectionProps {
    height: string | string[];
    imageSrc?: string;
    headline: string;
    description: string;
    wikifolioId?: string;
}

export const HideContent = ({ imageSrc, height, headline, description, wikifolioId, ...sectionProps }: IHideContentProps) => {
    const { buildLoginModalLink, buildRegisterModalLink } = useLinkBuilder();
    const toast = useWfToast();
    const toastVisible = useRef(false);
    const downloadMutation = useDownloadMutation();
    const { t } = useTranslation("common");

    const onError = (title: string) => {
        if (!toastVisible.current) {
            toastVisible.current = true;
            toast({ status: EnumToastStatus.Error, duration: 5000, title: title });
            setTimeout(() => {
                toastVisible.current = false;
            }, 5000);
        }
    };

    const onDownloadClick = async () => {
        try {
            await downloadMutation({
                wikifolioId: wikifolioId ?? "",
                type: EnumDownloadType.Portfolio,
            });
        } catch (error) {
            onError(t("general-error"));
        }
    };

    return (
        <Section pos="relative" h={height} mb={["20px", 5]} data-test-id={HIDE_CONTENT_TEST_ID} {...sectionProps}>
            <Flex justify="center" align="center" h="100%">
                {imageSrc && (
                    <Box position="absolute" minWidth={["100%", "690px", "633px", "777px"]} h={height}>
                        <Image alt="blank" src={imageSrc} layout="fill" role="presentation" />
                    </Box>
                )}
                <Flex
                    position={imageSrc ? "absolute" : "static"}
                    width="100%"
                    justify="center"
                    align="center"
                    direction="column"
                    top={imageSrc ? ["10%", "20%"] : undefined}
                    left="0"
                >
                    <Flex
                        width={["90%", "670px", "607px", "708px"]}
                        h={["100%", "354px"]}
                        align="center"
                        direction="column"
                        px={[2, 3, 3, 5]}
                        pt={["54px", "118px"]}
                        pb={[2, 5]}
                        bg="white"
                        boxShadow="sm"
                        rounded="8px"
                    >
                        <IconHidden pos="absolute" top={[-5, "-45px"]} boxSize={["80px", "136px"]} />
                        <WfHeading as="h2" w="100%" fontSize={["xl", "2xl"]} textAlign="center" mb={[0, 1]}>
                            {headline}
                        </WfHeading>
                        <Text textAlign="center" fontSize="md" bg="transparent" p={1} px={[0, 1]} mt={[0, 1]} mb={[1, 4, 1, 4]}>
                            {description}
                        </Text>
                        <Flex position="relative" justify="center" align="center" direction="row" width="100%">
                            <Button as={HashLinkUnstyled} href={buildRegisterModalLink()} size="md" variant="outline" w={["50%", "auto"]}>
                                {t("register")}
                            </Button>
                            <Button as={HashLinkUnstyled} href={buildLoginModalLink()} size="md" colorScheme="gray" ml={2} w={["50%", "auto"]}>
                                {t("login-button")}
                            </Button>
                        </Flex>
                    </Flex>
                    {wikifolioId && (
                        <Flex justify="center" align="center" direction="row" fontSize="sm">
                            <Text textAlign="center" fontSize="sm" fontWeight="normal" bg="transparent" p={1} pr={0.5} mt={2}>
                                {t("portfolio-download-text")}
                                <Span textDecor="underline" fontWeight="bold" onClick={onDownloadClick} cursor="pointer">
                                    {t("portfolio-download-link-text")}
                                </Span>
                                .
                            </Text>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Section>
    );
};
