import { MutableRefObject, useRef, useState, useEffect, useCallback } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import throttle from "lodash.throttle";

interface IAutoscrollingListParams {
    /**
     * How many pixels of an item must be out of view in the scrolling context to be considered not
     * on screen. This is used to determine the next item to scroll to when the user goes forward or
     * backward.
     */
    offscreenThreshold?: number;

    /**
     * How often the scroll handler runs in milliseconds. For performance reasons we shouldn't update
     * the scroll status on every frame.
     */
    scrollThrottle?: number;

    /**
     * A selector that determines what items can be scrolled to.
     */
    waypointSelector: string;

    /**
     * Avoid adding checkScrollPosition to scroll, resize events.
     */
    disableOnBreakpoint?: boolean[];
    /**
     * Because scroll calculations are expensive,
     * flip boolean to perform calculation after something major in DOM should be initial render.
     */
    canCalculateScroll?: boolean;
}

interface IAutoscrollingListResult {
    scrollContainerRef?: MutableRefObject<HTMLDivElement | null>;
    canGoBack: boolean;
    canGoForward: boolean;
    goBack: () => void;
    goForward: () => void;
}

/**
 * React hook for a list of elements with carousel-like horizontal scrolling behavior. This
 * provides callbacks to scroll back and forward between waypoints, based on what's currently
 * visible in the scrollport.
 */
export const useAutoscrollingList = ({
    offscreenThreshold = 0,
    scrollThrottle = 200,
    waypointSelector,
    disableOnBreakpoint = [false],
    canCalculateScroll = true,
}: IAutoscrollingListParams): IAutoscrollingListResult => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollContainer = scrollContainerRef.current;

    // True if autoscrolling back/forward is currently enabled, based on the current scroll position
    const [canGoBack, setCanScrollBack] = useState(false);
    const [canGoForward, setCanScrollForward] = useState(false);
    const isDisabled = useBreakpointValue(disableOnBreakpoint);

    const getAbsoluteScrollPosition = useCallback((scrollContainer: HTMLElement) => scrollContainer.scrollLeft, []);

    const scrollTo = (scrollContainer: HTMLElement, scrollPosition: number) => {
        scrollContainer.scrollLeft = scrollPosition;
    };

    const checkScrollPosition = throttle(
        useCallback(() => {
            if (scrollContainer == null) {
                return;
            }

            const scrollPosition = getAbsoluteScrollPosition(scrollContainer);
            const maxScrollPosition = scrollContainer.scrollWidth - scrollContainer.offsetWidth;

            setCanScrollBack(scrollPosition > offscreenThreshold);
            setCanScrollForward(scrollPosition < maxScrollPosition - offscreenThreshold);
        }, [offscreenThreshold, getAbsoluteScrollPosition, scrollContainer]),
        scrollThrottle
    );

    const goBack = useCallback(() => {
        if (scrollContainer === null) {
            return;
        }

        const getTrailingEdge = (el: Element) => el.getBoundingClientRect().left;
        const waypoints = Array.from(scrollContainer.querySelectorAll(waypointSelector)).reverse();
        const finalWaypoint = waypoints[0];
        const previousWaypoint = waypoints.find(waypoint => getTrailingEdge(waypoint) < getTrailingEdge(scrollContainer) - offscreenThreshold);

        if (finalWaypoint == null || previousWaypoint == null) {
            return;
        }

        // Scroll the scroll container so the end of the previous waypoint is at the end of the scrollport.
        const maxScrollPosition = scrollContainer.scrollWidth - scrollContainer.offsetWidth;

        scrollTo(scrollContainer, maxScrollPosition + previousWaypoint.getBoundingClientRect().right - finalWaypoint.getBoundingClientRect().right);
    }, [offscreenThreshold, scrollContainer, waypointSelector]);

    const goForward = useCallback(() => {
        if (scrollContainer === null) {
            return;
        }

        const getLeadingEdge = (el: Element) => el.getBoundingClientRect().right;
        const waypoints = Array.from(scrollContainer.querySelectorAll(waypointSelector));
        const firstWaypoint = waypoints[0];
        const nextWaypoint = waypoints.find(waypoint => getLeadingEdge(waypoint) > getLeadingEdge(scrollContainer) + offscreenThreshold);

        if (firstWaypoint == null || nextWaypoint == null) {
            return;
        }

        // Scroll the scroll container so the start of the next waypoint is at the start of the scrollport.
        scrollTo(scrollContainer, nextWaypoint.getBoundingClientRect().left - firstWaypoint.getBoundingClientRect().left);
    }, [offscreenThreshold, scrollContainer, waypointSelector]);

    // Check if we should enable/disable next/previous buttons initially after render, and again whenever the user scrolls.
    useEffect(() => {
        if (scrollContainer == null || isDisabled || !canCalculateScroll) {
            return;
        }

        checkScrollPosition();

        scrollContainer.addEventListener("scroll", checkScrollPosition, { passive: true });

        // Check on window resize, since this affects how big the scrollport is.
        window.addEventListener("resize", checkScrollPosition, { passive: true });
        return () => {
            window.removeEventListener("resize", checkScrollPosition);
            scrollContainer.removeEventListener("scroll", checkScrollPosition);
        };
    }, [checkScrollPosition, isDisabled, scrollContainer, canCalculateScroll]);

    return {
        scrollContainerRef,
        canGoForward,
        canGoBack,
        goBack,
        goForward,
    };
};
