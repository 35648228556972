import { IFontSize, IWfHeadingProps, WfHeading } from "src/components/base/wf-heading";

const H1_FONT_SIZE: Array<IFontSize> = ["4xl", "4xl", "5xl"];
const H2_FONT_SIZE: Array<IFontSize> = ["3xl", "3xl", "4xl"];
const H3_FONT_SIZE: Array<IFontSize> = ["2xl", "2xl", "3xl"];

const getTitleProps = (titleAsH2: boolean): IWfHeadingProps =>
    titleAsH2
        ? {
              as: "h2",
              fontSize: H2_FONT_SIZE,
              mb: [2, 3, 4],
          }
        : {
              as: "h3",
              fontSize: H3_FONT_SIZE,
              mb: [2, 2, 3],
          };

const getHeadlineProps = (titleAsH1: boolean): IWfHeadingProps =>
    titleAsH1
        ? {
              as: "h1",
              fontSize: H1_FONT_SIZE,
          }
        : {
              as: "h2",
              fontSize: H2_FONT_SIZE,
          };

interface IModuleHeadlineProps extends Omit<IWfHeadingProps, "as" | "fontSize"> {
    titleAsH1?: boolean;
}

export const ModulePrimaryHeadline = ({ titleAsH1 = false, ...props }: IModuleHeadlineProps) => {
    return <WfHeading {...getHeadlineProps(titleAsH1)} {...props} />;
};

interface IModuleSecondaryHeadlineProps extends Omit<IWfHeadingProps, "as" | "fontSize" | "mb"> {
    titleAsH2: boolean;
}

export const ModuleSecondaryHeadline = ({ titleAsH2, ...props }: IModuleSecondaryHeadlineProps) => (
    <WfHeading {...getTitleProps(titleAsH2)} {...props} />
);

interface IPrimaryHeadlineProps extends Omit<IWfHeadingProps, "as" | "fontSize"> {
    titleAsH1: boolean;
}
export const PrimaryHeadline = ({ titleAsH1, ...props }: IPrimaryHeadlineProps) => <WfHeading {...getHeadlineProps(titleAsH1)} {...props} />;
