import { Box, BoxProps, Grid, GridProps } from "@chakra-ui/react";

export const SCROLL_SNAP_PROPS = {
    "&::-webkit-scrollbar": {
        display: "none",
    },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    msScrollSnapType: "x mandatory",
    scrollSnapType: "x mandatory",
    scrollBehavior: "smooth",
};

export const ScrollSnap = ({ sx, ...gridProps }: GridProps) => {
    return (
        <Grid
            sx={{
                ...SCROLL_SNAP_PROPS,
                ...sx,
            }}
            {...gridProps}
        />
    );
};

export const ScrollSnapItem = ({ sx, ...boxProps }: BoxProps) => {
    return <Box sx={{ scrollSnapAlign: "center", ...sx }} {...boxProps} />;
};
