import { Box, FlexProps, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { WfHeading } from "src/components/base/wf-heading";
import { WfLinkUnstyled } from "src/components/base/wf-link";
import { ArrowButton } from "src/components/common/buttons/arrow-button";
import { HoverCard } from "src/components/common/hover-card";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { Ranking } from "src/components/common/ranking/ranking";
import { SCROLL_SNAP_PROPS } from "src/components/common/scroll-snap";
import { TraderAvatar } from "src/components/common/trader-avatar";
import { Container, CONTAINER_PX_SM_MD, CONTAINER_PX_SM_MD_NEGATIVE } from "src/components/layout/container";
import { useAutoscrollingList } from "src/hooks/dom/use-autoscrolling-list";
import { useGetUserName } from "src/hooks/use-get-user-name";
import { useLinkBuilder } from "src/hooks/use-link-builder";
import { ISelectedWikifolioData } from "./selected-wikifolios-module.types";

const CARD_WIDTH_SM_MD = [324, 450];
const CARD_WIDTH_LG = "358px";

const MAX_COLUMNS = 3;

const colorSchemeMap = {
    white: {
        bg: "white",
    },
    gray: {
        bg: "gray.50",
    },
};

export interface ISelectedWikifolioColumn {
    title?: string;
    wikifolios: ISelectedWikifolioData[];
}

export const WikifolioCard = ({ trader, ranking, symbol, hidePoints, shortDescription, ...flexProps }: ISelectedWikifolioData & FlexProps) => {
    const { buildWikifolioLink } = useLinkBuilder();
    const getUserName = useGetUserName();

    return (
        <HoverCard
            as={WfLinkUnstyled}
            variant="unstyled"
            href={buildWikifolioLink(symbol)}
            {...flexProps}
            boxShadow="xs"
            _hover={{ transform: "scale(1.02)", zIndex: 1 }}
        >
            <TraderAvatar flexShrink={0} size="sm" trader={trader} mr={1} />
            <Box flexGrow={1} mr={1} fontSize="sm">
                <Text noOfLines={1} wordBreak="break-all" fontWeight="bold">
                    {shortDescription}
                </Text>
                <Text noOfLines={1} wordBreak="break-all">
                    {getUserName(trader)}
                </Text>
            </Box>
            <Ranking flexShrink={0} align="flex-end" size="xs" data={ranking} labelProps={{ fontSize: "sm" }} hidePoints={hidePoints} />
        </HoverCard>
    );
};

export interface ISelectedWikifoliosModuleProps {
    title: string;
    wikifolioColumns: ISelectedWikifolioColumn[];
    description?: string;
    colorScheme?: keyof typeof colorSchemeMap;
    wfCardProps?: FlexProps;
    canCalculateScroll?: boolean;
}

export const SelectedWikifoliosModule = ({
    title,
    description,
    wikifolioColumns,
    colorScheme = "white",
    wfCardProps,
    canCalculateScroll = true,
}: ISelectedWikifoliosModuleProps) => {
    const hasCardsWithTitle = wikifolioColumns.some(column => Boolean(column.title));
    const { t } = useTranslation("common");

    const { scrollContainerRef, goBack, goForward, canGoForward, canGoBack } = useAutoscrollingList({
        waypointSelector: "[data-carousel-waypoint]",
        offscreenThreshold: 50,
        canCalculateScroll,
    });

    const hasNavButtonsLg = wikifolioColumns.length > MAX_COLUMNS;
    const isBgWhite = colorScheme === "white";
    const mbHeading = description ? 3 : 5;

    return (
        <Box {...colorSchemeMap[colorScheme]}>
            <Container py={!isBgWhite ? [6, 6, 8] : undefined}>
                <ModulePrimaryHeadline mb={mbHeading}>{title}</ModulePrimaryHeadline>
                {description && (
                    <Text fontSize="lg" mb={[3, 5]}>
                        {description}
                    </Text>
                )}
                <Stack
                    ref={scrollContainerRef}
                    spacing={[hasCardsWithTitle ? 5 : 1, 3]}
                    direction={["column", "row"]}
                    overflowX={["visible", "auto", "auto", hasNavButtonsLg ? "auto" : "visible"]}
                    pl={[0, ...CONTAINER_PX_SM_MD, hasNavButtonsLg ? 2 : 0]}
                    pr={[0, 2, 2, hasNavButtonsLg ? 2 : 0]}
                    ml={[0, ...CONTAINER_PX_SM_MD_NEGATIVE, hasNavButtonsLg ? -2 : 0]}
                    mr={[0, -2, -2, hasNavButtonsLg ? -2 : 0]}
                    py={5}
                    my={-5}
                    sx={SCROLL_SNAP_PROPS}
                >
                    {wikifolioColumns.map((column, index) => {
                        // Dev-Note: we need the adjustments for the last card because else there would be overflowing bugs in Safari
                        const isLastCard = index === wikifolioColumns.length - 1;
                        const isFirstCard = index === 0;

                        return (
                            <VStack
                                data-carousel-waypoint
                                key={index}
                                spacing={2}
                                w={["100%", ...CARD_WIDTH_SM_MD.map(val => (isLastCard ? val + 6 : val)), CARD_WIDTH_LG]}
                                pr={[0, isFirstCard ? 0 : 2, isLastCard ? 2 : 0, 0]}
                                flexShrink={0}
                            >
                                {column.title && (
                                    <WfHeading as="h2" fontSize="xl" w="100%" noOfLines={1} wordBreak="break-all">
                                        {column.title}
                                    </WfHeading>
                                )}
                                <VStack w="100%" spacing={1}>
                                    {column.wikifolios.map(wikifolio => (
                                        <WikifolioCard key={wikifolio.symbol} w="100%" {...wikifolio} {...wfCardProps} hidePoints />
                                    ))}
                                </VStack>
                            </VStack>
                        );
                    })}
                </Stack>
                <HStack mt={[3]} spacing={2} display={["none", "flex", "flex", hasNavButtonsLg ? "flex" : "none"]} justify="right">
                    <ArrowButton transform="rotate(180deg)" aria-label={t("nav-left")} isDisabled={!canGoBack} onClick={goBack} />
                    <ArrowButton aria-label={t("nav-right")} isDisabled={!canGoForward} onClick={goForward} />
                </HStack>
            </Container>
        </Box>
    );
};
