import { API_DETAIL_DOWNLOAD_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { EnumDownloadType } from "src/types/common-enum";
import { IErrorResponse } from "./types";

export enum EnumTimeRange {
    Today = 0,
    Last7Days = 1,
    Last30Days = 2,
    Last90Days = 3,
    YTD = 4,
    LastYear = 5,
    SinceEmission = 6,
    SinceCreation = 7,
}

export interface IDownloadRequestParams {
    wikifolioId: string;
    type: EnumDownloadType;
    range?: EnumTimeRange | null;
}

export function useDownloadMutation() {
    const { mutatePromise } = useMutation<Blob, IErrorResponse, IDownloadRequestParams>(API_DETAIL_DOWNLOAD_URL, {
        accept: "text/csv",
    });

    return mutatePromise;
}
